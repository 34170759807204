import React, { Component } from "react";

// COMPONENTS
import Pixi from "./components/Pixi";

// ASSETS

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      mobile: false
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ mobile: window.innerWidth <= 760 });
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  render() {
    let photo = "/assets/ken-original.jpg";
    if (this.state.hover) {
      photo = this.state.photos[this.getRandomInt(3)];
    }

    let display = (
      <div className="page">
        <div className="text-container">
          <div className="l1">
            <a href="http://kdgcv.com/">
              <span>Ken Griffen</span>
            </a>
          </div>
          <div className="l2">
            <span>On Holiday</span>
          </div>
          <div className="l3">
            <span>Indefinitely</span>
          </div>
          <div className="l4">
            <a href="http://kdgcv.com/">
              <span>Contact</span>
            </a>
          </div>
        </div>
        <div className="image-container">
          <a href="http://kdgcv.com/">
            <Pixi />
          </a>
        </div>
      </div>
    );

    if (this.state.mobile) {
      photo = "/assets/ken-original.jpg";
      display = (
        <div className="page">
          <div className="text-container-mobile">
            <div className="l1">
              <a href="http://kdgcv.com/">
                <span>Ken Griffen</span>
              </a>
            </div>
            <div className="l2">
              <span>On Holiday</span>
            </div>
            <div className="l3">
              <span>Indefinitely</span>
            </div>
            <div className="l4">
              <a href="http://kdgcv.com/">
                <span>Contact</span>
              </a>
            </div>
          </div>
          <div className="image-container-mobile">
            <img className="image-mobile" src={photo} alt="ken" />
          </div>
        </div>
      );
    }

    return <div>{display}</div>;
  }
}

export default App;
