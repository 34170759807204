import React, { Component } from "react";
import * as PIXI from "pixi.js";
// COMPONENTS

class Pixi extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.pixi_cnt = null;
    this.rt = [];
    this.container = null;
    this.current = 0;
    this.bg = null;
    this.displacementFilter = null;
    this.brush = null;
    this.dragging = true;
    this.app = new PIXI.Application({
      width: 600,
      height: 808.53,
      transparent: true,
      antialias: true,
      autoStart: false,
      resolution: 1
    });
    this.updatePixiCnt = this.updatePixiCnt.bind(this);
    this.setup = this.setup.bind(this);
    this.initialize = this.initialize.bind(this);
    this.snap = this.snap.bind(this);
    this.onPointerUp = this.onPointerUp.bind(this);
    this.onPointerDown = this.onPointerDown.bind(this);
    this.onPointerMove = this.onPointerMove.bind(this);
  }

  componentDidMount() {
    console.log(PIXI.loader.resources);
    for (var i = 0; i < 3; i++) {
      this.rt.push(
        PIXI.RenderTexture.create(this.app.screen.width, this.app.screen.height)
      );
    }
  }

  componentWillUnmount() {
    PIXI.loader.reset();
  }

  componentDidUpdate() {}

  updatePixiCnt(element) {
    // the element is the DOM object that we will use
    // as a container to add pixi stage(canvas)
    this.pixi_cnt = element;

    // now add the application to the DOM element we got from Ref
    if (this.pixi_cnt && this.pixi_cnt.children.length <= 0) {
      this.pixi_cnt.appendChild(this.app.view);
      this.setup();
    }
  }

  setup() {
    this.container = new PIXI.Container();
    this.app.stage.addChild(this.container);

    PIXI.loader
      .add("pic", "/assets/ken-original.jpg")
      .add("displacement", "/assets/displacement-one.png")
      .load(this.initialize);
  }

  initialize() {
    let picture = new PIXI.Sprite(PIXI.loader.resources.pic.texture);
    picture.width = this.app.screen.width;
    picture.height = this.app.screen.height;
    // this.app.stage.addChild(picture);
    // this.app.render.backgroundColor = 0xffdad8;
    this.app.renderer.render(picture, this.rt[0]);

    this.bg = new PIXI.Sprite(this.rt[0]);

    this.brush = new PIXI.Sprite(PIXI.loader.resources.displacement.texture);
    this.brush.anchor.set(0.5);
    this.displacementFilter = new PIXI.filters.DisplacementFilter(this.brush);
    this.container.filters = [this.displacementFilter];
    this.displacementFilter.scale.x = 5;
    this.displacementFilter.scale.y = 5;

    this.container.addChild(this.bg, this.brush);

    this.app.stage.interactive = true;

    this.app.stage
      .on("pointerdown", this.onPointerDown)
      .on("pointerup", this.onPointerUp)
      .on("pointermove", this.onPointerMove);

    this.app.start();
  }

  snap(event) {
    this.app.renderer.render(this.app.stage, this.rt[2 - this.current]);
    this.bg.texture = this.rt[2 - this.current];
    this.current = 2 - this.current;
  }

  onPointerDown(event) {
    this.dragging = true;
    this.onPointerMove(event);
  }

  // this part is cool - detect directional mouse movement
  onPointerMove(event) {
    let x = event.data.global.x;
    let y = event.data.global.y;
    this.displacementFilter.scale.x = Math.atan(x - this.brush.x) * 6;
    this.displacementFilter.scale.y = Math.atan(y - this.brush.y) * 6;

    this.brush.position.copy(event.data.global);

    if (this.dragging) {
      this.snap(event);
    }
  }

  onPointerUp() {
    this.dragging = false;
  }

  render() {
    return (
      <div>
        <div
          className="pixi-distortion-layer"
          ref={element => {
            this.updatePixiCnt(element);
          }}
        />
        <div className="pixi-base-layer">
          <img
            src="/assets/ken-layer-base.png"
            alt="ken-base"
            className="base-image"
          />
        </div>
      </div>
    );
  }
}

export default Pixi;
